import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Select, Button } from 'antd';
import { setFinyear, setCompany } from '../../../redux/actions';
import '../styles/login.css';

const { Option } = Select;

export const FinYear = () => {
    const [companies, setCompanies] = useState([]);
    const [finyears, setFinyears] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { finyear, company, auth: { user } = {} } = useSelector((state) => state);
    const [form] = Form.useForm();

    useEffect(() => {
        setCompanies(company?.companies || []);
    }, [company?.companies]);

    useEffect(() => {
        if (user && finyear?.finyears) {
            const userFinyears = user.Fin_Year_Ids?.map((id) => {
                return finyear.finyears.find((year) => parseInt(year.id) === parseInt(id));
            });
            setFinyears(userFinyears);
        }
    }, [finyear?.finyears, user]);

    const onChangeFinYear = (val) => {
        const filteredCompanies = company?.companies.filter((company) => (company.Fin_Year_Id === val));
        setCompanies(filteredCompanies);
        form.resetFields(['company']);
    }

    const onFinish = (values) => {
        const finyear = finyears.find((data) => data.id === values.finyear);
        console.log('Values:', values, finyear);
        dispatch(setFinyear(finyear));
        if (values.company) {
            const company = companies.find((data) => data.id === values.company);
            dispatch(setCompany(company));
        }
        if (!companies.length) {
            navigate('/masters/company');
        } else {
            navigate('/dashboard');
        }
    };

    const onCancel = () => {
          form.resetFields();
    };

    return (
        <Form
            form={form}
            name='finance'
            className='form'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            autoComplete='off'
        >
            <Form.Item
                label='Finance Year'
                name='finyear'
                rules={[{ required: true, message: 'Please select finance year!' }]}
            >
                <Select onChange={onChangeFinYear}>
                    {finyears.map((data, index) => (
                        <Option key={index} value={data.id}>{data.Year}</Option>
                    ))}
                </Select>
            </Form.Item>
            {companies.length ? (
                <Form.Item
                    label='Company'
                    name='company'
                    rules={[{ required: true, message: 'Please select company!' }]}
                >
                    <Select>
                        {companies.map((data, index) => (
                            <Option key={index} value={data.id}>{data.Company_Name}</Option>
                        ))}
                    </Select>
                </Form.Item>
            ) : null}
            <div className='btn-wrap'>
                <Button onClick={onCancel}>
                    Cancel
                </Button>
                <Button type='primary' htmlType='submit'>
                    {companies.length ? 'Submit' : 'Create Company'}
                </Button>
            </div>
        </Form>
    );
}