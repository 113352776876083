import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Spin } from 'antd';
import { LoginView } from '../Login/pages';
import { ProtectedRoute } from './ProtectedRoute';
import { AuthProvider } from './AuthProvider';
import Company from '../Masters/Company/pages';
import Broker from '../Masters/Broker/pages';
import Schedule from '../Masters/Schedule/pages';
import Transporter from '../Masters/Transporter/pages';

export const AppRoutes = () => {

  return (
    <AuthProvider>
      <Routes>
        <Route
          path='/'
          loader={<Spin spinning={true}/>}
          element={(
            <ProtectedRoute>
              <div>Dashboard</div>
            </ProtectedRoute>
          )}
        />
        <Route
          path='/login'
          loader={<Spin spinning={true}/>}
          element={(
            <ProtectedRoute>
              <LoginView/>
            </ProtectedRoute>
          )}
        />
        <Route
          path='/dashboard'
          loader={<Spin spinning={true}/>}
          element={(
            <ProtectedRoute>
              <div>Dashboard</div>
            </ProtectedRoute>
          )}
        />
        <Route
          path='/masters/company'
          exact={true}
          loader={<Spin spinning={true}/>}
          element={(
            <ProtectedRoute>
              <Company/>
            </ProtectedRoute>
          )}
        />
        <Route
          path='/masters/schedule'
          loader={<Spin spinning={true}/>}
          element={(
            <ProtectedRoute>
              <Schedule/>
            </ProtectedRoute>
          )}
        />
        <Route
          path='/masters/transporter'
          loader={<Spin spinning={true}/>}
          element={(
            <ProtectedRoute>
              <Transporter/>
            </ProtectedRoute>
          )}
        />
        <Route
          path='/masters/broker'
          loader={<Spin spinning={true}/>}  
          element={(
            <ProtectedRoute>
              <Broker/>
            </ProtectedRoute>
          )}
        />
        <Route
          path='/masters/state'
          loader={<Spin spinning={true}/>}
          element={(
            <ProtectedRoute>
              <div>State</div>
            </ProtectedRoute>
          )}
        />
      </Routes>
    </AuthProvider>
  );
};