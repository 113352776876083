import { SCHEDULE_REQUEST, CREATE_SCHEDULE, GET_SCHEDULES, UPDATE_SCHEDULE, DELETE_SCHEDULE, SCHEDULE_REQUEST_FAILURE } from '../types';

const initialState = {
  schedules: [],
  loading: false,
  error: null,
};

const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCHEDULE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case GET_SCHEDULES: {
      return {
        ...state,
        schedules: [...action.payload],
        loading: false,
        error: null
      }
    }
    case CREATE_SCHEDULE:
      return {
        ...state,
        schedules: [...state.schedules, action.payload],
        loading: false,
        error: null
      };
    case UPDATE_SCHEDULE:
      const schedulesData = state.schedules.slice(0);
      const uIndex = schedulesData.findIndex((data) => data.id === action.payload.id);
      schedulesData.splice(uIndex, 1, { ...action.payload.data });
      return {
        ...state,
        schedules: [ ...schedulesData ],
        loading: false,
        error: null
      };
    case DELETE_SCHEDULE:
      const schedules = state.schedules?.slice(0);
      const index = schedules.findIndex((data) => data.id === action.id);
      if (index > -1) {
        schedules.splice(index, 1);
      }
      return {
        ...state,
        schedules: [...schedules],
        loading: false,
        error: null
      }
    case SCHEDULE_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }
    default:
      return state;
  }
};

export default scheduleReducer;
