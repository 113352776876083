import React from 'react';
import { Flex, Form, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { Item } = Form;

export const CompanyLogoSign = ({ form }) => {

    const onChange = (fileList, name) => {
        return new Promise((resolve) => {
            const file = fileList.file?.originFileObj;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
            const img = document.createElement('img');
            img.src = reader.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.naturalWidth;
                canvas.height = img.naturalHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                ctx.fillStyle = 'red';
                ctx.textBaseline = 'middle';
                ctx.font = '33px Arial';
                ctx.fillText('Ant Design', 20, 20);
                canvas.toBlob((result) => {
                    form.setFieldValue(name, result);
                    resolve(result)
                });
            };
            };
        });
    }

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    return (
        <div style={{ width: '100%' }}>
            <Flex gap={100}>
                <div>
                    <div style={{ fontWeight: 'bold' }}>Logo</div>
                    <Item name={'Logo'}>
                        <Upload
                            className='logo'
                            listType={'picture-card'}
                            maxCount={1}
                            onChange={(fileList) => onChange(fileList, 'Logo')}
                        >
                            {uploadButton}
                        </Upload>
                    </Item>
                </div>
                <div>
                    <div style={{ fontWeight: 'bold' }}>Sign</div>
                    <Item name={'Sign'}>
                        <Upload
                            className='sign'
                            listType={'picture-card'}
                            maxCount={1}
                            onChange={(fileList) => onChange(fileList, 'Sign')}
                        >
                            {uploadButton}
                        </Upload>
                    </Item>
                </div>
            </Flex>
        </div>
    );
}