import axios from '../../config/axiosConfig';
import { GET_STATES } from '../types';

export const getStates = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get('/state/getAll');
            if (response.data) {
                dispatch({ type: GET_STATES, payload: response.data });
            }
        } catch (error) {
            console.error(error);
    }
  };
};