import React, { useEffect, useState } from 'react';
import { Button, Typography, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { TransporterModal } from './TransporterModal';
import { TransporterTable } from './TransportersTable';
import { getTransporters } from '../../../../redux/actions';

const { Title } = Typography;

export const TransporterView = () => {
    const [open, setOpen] = useState(false);
    const [selectedTransporter, setSelectedTransporter] = useState(null);
    const state = useSelector((state) => state.transporter);
    const [api, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTransporters());
    }, [dispatch]);

    useEffect(() => {
        const openErrorNotification = () => {
          api.error({
            message: 'Transporter Failed',
            description: state.error,
            placement: 'topRight',
          });
        };
        if (state.error) {
          openErrorNotification();
        }
      }, [state.error, api]);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => {
        setSelectedTransporter(null);
        setOpen(false);
    }

    return (
        <div className='container'>
            {contextHolder}
            <TransporterModal
                open={open}
                onClose={onCloseModal}
                dispatch={dispatch}
                broker={selectedTransporter}
            />
            <Title>Transporter</Title>
            <div className='add-broker'>
                <Button type='primary' onClick={onOpenModal}><PlusOutlined />Add Transporter</Button>
            </div>
            <TransporterTable
                data={state.transporters || []}
                loading={state.loading}
                dispatch={dispatch}
                openModal={onOpenModal}
                setSelectedTransporter={setSelectedTransporter}
            />
        </div>
    );
}

export default TransporterView;