import axios from '../../config/axiosConfig';
import { CREATE_COMPANY, GET_COMPANIES, COMPANY_REQUEST, COMPANY_REQUEST_FAILURE, SET_COMPANY } from '../types';

export const getCompanies = () => {
    return async (dispatch, getState) => {
        try {
            const { auth } = getState();
            dispatch({ type: COMPANY_REQUEST });
            const response = await axios.get('/company/getAll', { params: { userId: auth.user?.id }});
            if (response.data) {
                dispatch({ type: GET_COMPANIES, payload: response.data });
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: COMPANY_REQUEST_FAILURE, error: error.message });
        }
    };
};

export const createCompany = (company) => {
    return async (dispatch, getState) => {
        try {
            const { auth } = getState();
            dispatch({ type: COMPANY_REQUEST });
            const newCompany = {
                ...company,
                Fin_Year_Id: auth.finyear?.id,
                Created_User: auth.user?.id,
                Modified_User: auth.user?.id
            };

            const response = await axios.post('/company/add', newCompany, { params: { userId: auth.user?.id }});
            if (response.data) {
                dispatch({ type: CREATE_COMPANY, payload: response.data });
                const company = localStorage.getItem('company');
                if (!company) {
                    dispatch({ type: SET_COMPANY, payload: response.data })
                }
            } else {
                dispatch({ type: COMPANY_REQUEST_FAILURE, error: 'Company create failed.' });
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: COMPANY_REQUEST_FAILURE, error: error.message });
        }
    };
};