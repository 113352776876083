import { BROKER_REQUEST, CREATE_BROKER, GET_BROKERS, UPDATE_BROKER, DELETE_BROKER, BROKER_REQUEST_FAILURE } from '../types';

const initialState = {
  brokers: [],
  loading: false,
  error: null,
};

const brokerReducer = (state = initialState, action) => {
  switch (action.type) {
    case BROKER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case GET_BROKERS: {
      return {
        ...state,
        brokers: [...action.payload],
        loading: false,
        error: null
      }
    }
    case CREATE_BROKER:
      return {
        ...state,
        brokers: [...state.brokers, action.payload],
        loading: false,
        error: null
      };
    case UPDATE_BROKER:
      const brokersData = state.brokers.slice(0);
      const uIndex = brokersData.findIndex((data) => data.id === action.payload.id);
      brokersData.splice(uIndex, 1, { ...action.payload.data });
      return {
        ...state,
        brokers: [ ...brokersData ],
        loading: false,
        error: null
      };
    case DELETE_BROKER:
      const brokers = state.brokers?.slice(0);
      const index = brokers.findIndex((data) => data.id === action.id);
      if (index > -1) {
        brokers.splice(index, 1);
      }
      return {
        ...state,
        brokers: [...brokers],
        loading: false,
        error: null
      }
    case BROKER_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }
    default:
      return state;
  }
};

export default brokerReducer;
