import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { useAuth } from './AuthProvider';

export const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const { company } = useSelector((state) => state.auth);
    const { isAuthenticated, loading } = useAuth();
    if (loading) {
        return <Spin spinning={loading} />;
    }

    if (isAuthenticated) {
        if (location.pathname === '/login' || location.pathname === '/') {
            if (company?.id) {
                return (<Navigate to={'/dashboard'} state={{ from: location }} replace />);
            } else {
                return (<Navigate to={'/masters/company'} state={{ from: location }} replace />);
            }
        } else if (!company?.id && location.pathname !== '/masters/company') {
            return (<Navigate to={'/masters/company'} state={{ from: location }} replace />);
        }
    } else if (!isAuthenticated && location.pathname !== '/login') {
        return (<Navigate to={'/login'} state={{ from: location }} replace />);
    }
    return children;
};