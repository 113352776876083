import axios from '../../config/axiosConfig';
import { CREATE_TRANSPORTER, GET_TRANSPORTERS, TRANSPORTER_REQUEST, DELETE_TRANSPORTER, UPDATE_TRANSPORTER, TRANSPORTER_REQUEST_FAILURE } from '../types';

export const getTransporters = () => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: TRANSPORTER_REQUEST });
            const { auth } = getState();
            const response = await axios.get('/transporter/getAll', { params: { userId: auth.user?.id, companyId: auth.company?.id }});
            if (response.data) {
                dispatch({ type: GET_TRANSPORTERS, payload: response.data });
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: TRANSPORTER_REQUEST_FAILURE, error: error.message });
        }
    };
};

export const createTransporter = (transporter) => {
    return async (dispatch, getState) => {
        try {
            const { auth } = getState();
            dispatch({ type: TRANSPORTER_REQUEST });
            const newTransporter = {
                ...transporter,
                Company_Id: auth.company?.id,
                Created_User: auth.user?.id,
                Modified_User: auth.user?.id
            };

            const response = await axios.post('/transporter/add', newTransporter);
            if (response.data) {
                dispatch({ type: CREATE_TRANSPORTER, payload: response.data });
            } else {
                dispatch({ type: TRANSPORTER_REQUEST_FAILURE, error: 'Transporter create failed.' });
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: TRANSPORTER_REQUEST_FAILURE, error: error.message });
        }
    };
};

export const updateTransporter = (id, data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: TRANSPORTER_REQUEST });
            const response = await axios.put('/transporter/update', { id, data });
            if (response.data) {
                dispatch({ type: UPDATE_TRANSPORTER, payload: { id, data: response.data } });
            } else {
                dispatch({ type: TRANSPORTER_REQUEST_FAILURE, error: 'Transporter update failed.' });
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: TRANSPORTER_REQUEST_FAILURE, error: error.message });
        }
    };
};

export const deleteTransporter = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: TRANSPORTER_REQUEST });
            const response = await axios.delete(`/transporter/delete/${id}`);
            if (response.data) {
                dispatch({ type: DELETE_TRANSPORTER, id: id });
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: TRANSPORTER_REQUEST_FAILURE, error: error.message });
        }
    };
};