import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Typography } from 'antd';
import { Login } from './Login';
import { FinYear } from './FinYear';
import '../styles/login.css';

const { Title } = Typography;

export const LoginView = () => {
    const auth = useSelector((state) => state.auth);

    return (
        <Card className='card' bordered={false}>
            <Title className='title'>{!auth.token ? 'Login' : 'Finance Year'}</Title>
            {!auth.token ? <Login/> : <FinYear/>}
        </Card>
    );
}