import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'http://127.0.0.1:4000',
    headers: {
    //   'Authorization': 'Bearer YOUR_TOKEN',
      'Content-Type': 'application/json'
    },
    responseType: 'json'
});

export default axiosInstance;