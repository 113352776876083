import { combineReducers } from 'redux';
import authReducer from './auth-reducer';
import stateReducer from './state-reducer';
import finyearReducer from './finyear-reducer';
import companyReducer from './company-reducer';
import brokerReducer from './broker-reducer';
import scheduleReducer from './schedule-reducer';
import transporterReducer from './transporter-reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  state: stateReducer,
  finyear: finyearReducer,
  company: companyReducer,
  broker: brokerReducer,
  schedule: scheduleReducer,
  transporter: transporterReducer
});

export default rootReducer;
