import React from 'react';
import { Form, Input, Card, Flex, Select, Checkbox, DatePicker } from 'antd';
import { useSelector } from 'react-redux';
import { getDatesFromFinyear } from '../../../../utils/finyear';

const { Item } = Form;

export const RegistrationDetails = () => {
    const itemLayout = { labelCol: { span: 9 } };
    const noLabelIteam = { wrapperCol: { span: 20, offset: 6 }};
    const withLabelItem = { labelCol: { offset: 1, span: 5 }, wrapperCol: { span: 20, offset: 0 }};
    const finyear = useSelector((state) => state.auth?.finyear);

    return (
        <div>
            <Flex vertical={true} gap={'middle'}>
                <Flex gap={'small'}>
                    <Card title={'Registration'} className='card'>
                        <Item noStyle>
                        <Flex gap={'small'}>
                            <div style={{ width: '50%' }}>
                                <Item name={'GSTIN_No'} label={'GSTIN/UIN NO'} {...itemLayout}>
                                    <Input/>
                                </Item>
                                <Item name={'PAN_NO'} label={'PAN NO'} {...itemLayout}>
                                    <Input/>
                                </Item>
                                <Item name={'MSME_No'} label={'Udyam Reg. No'} {...itemLayout}>
                                    <Input/>
                                </Item>
                                <Item name={'MSME_Reg_Date'} label={'MSME Reg. Date'} {...itemLayout}>
                                    <DatePicker
                                        disabledDate={(currDate) => {
                                            const { from, to } = getDatesFromFinyear(finyear);
                                            return (currDate < from || currDate > to);
                                        }}
                                    />
                                </Item>
                                <Item name={'MSME_Category'} label={'Business Category'} {...itemLayout}>
                                    <Select
                                        mode="tags"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        maxCount={1}
                                    />
                                </Item>
                                <Item name={'TAN_No'} label={'T.A.N. No'} {...itemLayout}>
                                    <Input/>
                                </Item>
                                <Item name={'CIN_No'} label={'CIN No'} {...itemLayout}>
                                    <Input/>
                                </Item>
                                <Item name={'ServiceTax_No'} label={'Service Tax No'} {...itemLayout}>
                                    <Input/>
                                </Item>
                                <Item name={'Short_Code'} label={'Company Short Code'} {...itemLayout}>
                                    <Input/>
                                </Item>
                            </div>
                            <div style={{ width: '50%' }}>
                                <Item label={'TDS % 1'} style={{ marginBottom: '0' }} {...withLabelItem}>
                                    <Flex gap={'small'}>
                                        <Item name={'Tds_Perc1'}>
                                            <Input type='number'/>
                                        </Item>
                                        <Item name={'Tds_Perc2'} label={'TDS % 2'}>
                                            <Input type='number'/>
                                        </Item>
                                    </Flex>
                                </Item>
                                <Item name={'Language'} label={'Language'} {...withLabelItem}>
                                    <Select
                                        mode="tags"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        maxCount={1}
                                    />
                                </Item>
                                <Item name={'IT_Place'} label={'I.T. Place'} {...withLabelItem}>
                                    <Input/>
                                </Item>
                                <Item name={'IE_Code'} label={'I.E Code'} {...withLabelItem}>
                                    <Input/>
                                </Item>
                                <Item {...noLabelIteam}>
                                    <Flex gap={'small'}>
                                        <Item noStyle name={'Eway_Bill'}><Checkbox/>E-Way Bill</Item>    
                                        <Item noStyle name={'E_Invoice'} initialValue={true} valuePropName='checked'><Checkbox/>E-Invoice</Item>
                                    </Flex>                                        
                                </Item>
                                <Item {...noLabelIteam} style={{ marginBottom: '0' }}>EWay Bill User Id</Item>
                                <Item name={'EWay_Bill_User_Id'} {...noLabelIteam}>
                                    <Input/>
                                </Item>
                                <Item {...noLabelIteam} style={{ marginBottom: '0' }}>EWay Bill Password</Item>
                                <Item name={'EWay_Bill_Password'} {...noLabelIteam}>
                                    <Input.Password/>
                                </Item>
                            </div>
                    
                        </Flex>
                        </Item>
                    </Card>
                    <Card title={'Bank Details'} className='card' style={{ minHeight: '430px' }}>
                        <Item name={'Bank_Name'} label={'Bank Name'}>
                            <Select
                                mode="tags"
                                allowClear={true}
                                style={{ width: '100%' }}
                                maxCount={1}
                            />
                        </Item>
                        <Item name={'Branch_Name'} label={'Branch'}>
                            <Input/>
                        </Item>
                        <Item name={'Account_No'}  label={'Account No.'}>
                            <Input/>
                        </Item>
                        <Item name={'IFSC_Code'} label={'IFSC Code'}>
                            <Input/>
                        </Item>
                        <Item name={'UPI_Id'} label={'UPI Id'}>
                            <Input/>
                        </Item>
                    </Card>
                </Flex>
                <Flex gap={'small'}>
                    <Card title={'Factory Address'} className='card'>
                        <Item name={'SMS_Company'} label={'SMS Company'}>
                            <Input/>
                        </Item>
                        <Item name={'SMS_Address1'} label={'SMS Address'}>
                            <Input.TextArea rows={3}/>
                        </Item>
                        <Item label={'SMTP Port'} style={{ marginBottom: '0' }}>
                            <Flex gap={'small'}>
                                <Item name={'SMTP_Port'}>
                                    <Input style={{ width: '150px'}}/>
                                </Item>
                                    <Item name={'SMTP_Host'} label={'SMTP Host'}>
                                    <Input/>
                                </Item>
                            </Flex>
                        </Item>
                        <Item name={'SMTP_Email'} label={'E-mail'}>
                            <Input/>
                        </Item>
                        <Item name={'Email_Pwd'} label={'Password'}>
                            <Input.Password/>
                        </Item>
                        <Item name={'Email_Sign'} label={'E-mail Sign'}>
                            <Input.TextArea rows={2}/>
                        </Item>
                    </Card>
                    <Card title={'Excise Details'} className='card' style={{ minHeight: '365px' }}>
                        <Item name={'Excise_Reg_No'} label={'Excise Reg. No'}>
                            <Input/>
                        </Item>
                        <Item name={'Range'} label={'Range'}>
                            <Input/>
                        </Item>
                        <Item name={'Division'} label={'Division'}>
                            <Input/>
                        </Item>
                        <Item name={'Commissionarate'} label={'Commissionarate'}>
                            <Input/>
                        </Item>
                        <Item name={'PLA_No'} label={'PLA No.'}>
                            <Input/>
                        </Item>
                        <Item name={'Excise_Rule'} label={'Excise Rule'}>
                            <Input.TextArea rows={3}/>
                        </Item>
                    </Card>
                </Flex>
            </Flex>
        </div>
    );
}