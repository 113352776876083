import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Button, Flex, Form, Spin, notification } from 'antd';
import { NameAddress } from './NameAddress';
import { RegistrationDetails } from './RegistrationDetails';
import { CompanyLogoSign } from './CompanyLogoSign';
import { getStates, createCompany } from '../../../../redux/actions';
import '../styles/company.css';

const { useForm } = Form;

export const CompanyForm = () => {
  const [states, setStates] = useState([]);
  const [tab, setTab] = useState('1');
  const [form] = useForm();
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const { state, company: { loading, error } } = useSelector(store => store);

  useEffect(() => {
    dispatch(getStates());
  }, [dispatch]);

  useEffect(() => {
    setStates(state.states || []);
  }, [state]);

  useEffect(() => {
    const openErrorNotification = () => {
      api.error({
        message: `Create failed`,
        description: error,
        placement: 'topRight',
      });
    };
    if (error) {
      openErrorNotification();
    }
  }, [error]);

  const items = [
    {
      key: '1',
      label: 'Name and Address',
      children: <NameAddress form={form} states={states} />,
    },
    {
      key: '2',
      label: 'Registration Details',
      children: <RegistrationDetails />,
    },
    {
      key: '3',
      label: 'Company Logo / Sign',
      children: <CompanyLogoSign form={form} />,
    },
  ];

  const onCancel = () => {
    form.resetFields();
  }

  const onChange = (key) => {
    setTab(key); 
  };

  const onFinishForm = (values) => {
    const company = {
      ...values,
      Company_Name: values.Company_Name && values.Company_Name[0],
      City: values.City && values.City[0],
      MSME_Category: values.MSME_Category && values.MSME_Category[0],
      Language: values.Language && values.Language[0],
      Bank_Name: values.Bank_Name && values.Bank_Name[0],
    }
    dispatch(createCompany(company));
    form.resetFields();
    setTab('1');
  };

  const onFinishFailedForm = (error) => {
    const errorField = error.errorFields.map((val) => val.name).reduce((cur, prev) => ([...prev, ...cur]), []);
    if (errorField.includes('Company_Name')) {
      setTab('1');
    }
  }

  const operations = (
    <Flex gap={'small'}>
      <Button onClick={onCancel}>Cancel</Button>
      <Button type='primary' onClick={form.submit}>Save</Button>
    </Flex>
  );

  return (
    <div className='container'>
      {contextHolder}
      <Spin spinning={loading}>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          form={form}
          name={'company-form'}
          onFinish={onFinishForm}
          onFinishFailed={onFinishFailedForm}
        >
          <Tabs
              tabBarExtraContent={operations}
              activeKey={tab}
              items={items}
              onChange={onChange}
            />
        </Form>
      </Spin>
    </div>
  );
}