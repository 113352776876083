import React from 'react';
import { Form, Input, Card, Flex, Select, Checkbox } from 'antd';

const { Item } = Form;
const { Option } = Select;

export const NameAddress = ({ form, states }) => {
    const itemLayout = { wrapperCol: { span: 20, offset: 4 } };
    const onChange = (value, id) => {
        const dbId = id === 'State' ? 'State_Name' : 'State_Code';
        const state = states.find((data) => value === data[dbId]);
        form.setFieldValue('State', state.State_Name);
        form.setFieldValue('State_Code', state.State_Code);
    }

    return (
        <div>
            <Flex vertical={true} gap={'middle'}>
                <Flex gap={'small'}>
                    <Card title={'Name'} className='card' style={{ minHeight: '310px' }}>
                        <Item name={'Company_Name'} label={'Company Name'} rules={[{ required: true, message: "Company name is required!" }]}>
                            <Select
                                mode="tags"
                                allowClear={true}
                                style={{ width: '100%' }}
                                maxCount={1}
                            />
                        </Item>
                        <Item name={'Propriter'} label={'Proprietor'}>
                            <Input/>
                        </Item>
                        <Item name={'Common_Company'} {...itemLayout}>
                            <Checkbox>Common Production</Checkbox>
                        </Item>
                        <Item name={'Common_Master'} {...itemLayout}>
                            <Checkbox>Common Master</Checkbox>
                        </Item>
                        <Item name={'Common_Order'} {...itemLayout}>
                            <Checkbox>Common Order</Checkbox>
                        </Item>
                    </Card>
                    <Card title={'Office Address'} className='card'>
                        <Item name={'Off_Address1'} label={'Address'}>
                            <Input/>
                        </Item>
                        <Item name={'Off_Address2'} {...itemLayout}>
                            <Input/>
                        </Item>
                        <Item name={'Off_Address3'} {...itemLayout}>
                            <Input/>
                        </Item>
                        <Item name={'City'} label={'City'}>
                            <Select
                                mode="tags"
                                allowClear={true}
                                style={{ width: '100%' }}
                                maxCount={1}
                            />
                        </Item>
                        <Item label={'State'}>
                            <Flex gap={'small'}>
                                <Item noStyle={true} name={'State'}>
                                    <Select style={{ width: '300px' }} onChange={(val) => onChange(val, 'State')}>
                                        {states.map((data, index) => (
                                            <Option key={index} value={data.State_Name}>{data.State_Name}</Option>
                                        ))}
                                    </Select>
                                </Item>
                                <Item noStyle={true} name={'State_Code'}>
                                    <Select style={{ width: '100px' }} onChange={(val) => onChange(val, 'State_Code')}>
                                        {states.map((data, index) => (
                                            <Option key={index} value={data.State_Code}>{data.State_Code}</Option>
                                        ))}
                                    </Select>
                                </Item>
                                <Item label={'Pin Code'} name={'Pin_Code'}>
                                    <Input type='number'/>
                                </Item>
                            </Flex>
                        </Item>
                        <Item name={'Group_Name'} label={'Group Name'}>
                            <Select
                                mode="tags"
                                allowClear={true}
                                style={{ width: '100%' }}
                                maxCount={1}
                            />
                        </Item>
                    </Card>
                </Flex>
                <Flex gap={'small'}>
                    <Card title={'Factory Address'} className='card'>
                        <Item name={'Fact_Address1'} label={'Address 1'}>
                            <Input />
                        </Item>
                        <Item name={'Fact_Address2'} {...itemLayout}>
                            <Input />
                        </Item>
                        <Item name={'Fact_Address3'} {...itemLayout}>
                            <Input />
                        </Item>
                        <Item label={'Pin code'} name={'Pin_Code1'} labelCol={{ span: 19 }}>
                            <Input type='number'/>
                        </Item>
                        <Item name={'Fact_Address11'} label={'Address 2'}>
                            <Input />
                        </Item>
                        <Item name={'Fact_Address12'} {...itemLayout}>
                            <Input />
                        </Item>
                        <Item name={'Fact_Address13'} {...itemLayout}>
                            <Input />
                        </Item>
                        <Item label={'Pin code'} name={'Pin_Code2'} labelCol={{ span: 19 }}>
                            <Input type={'number'}/>
                        </Item>
                    </Card>
                    <Card title={'Contact Details'} className='card' style={{ minHeight: '387px' }}>
                        <Item name={'Office_Phone'} label={'Office Phone'}>
                            <Input/>
                        </Item>
                        <Item name={'Mobile'} label={'Mobile'}>
                            <Input/>
                        </Item>
                        <Item name={'Fact_Phone'} label={'Fact. Phone'}>
                            <Input/>
                        </Item>
                        <Item name={'Email'} label={'Email'}>
                            <Input/>
                        </Item>
                        <Item name={'Company_Nature'} label={'Nature'}>
                            <Input/>
                        </Item>
                        <Item name={'God_Name'} label={'God Name'}>
                            <Input/>
                        </Item>
                    </Card>
                </Flex>
            </Flex>
        </div>
    );
}