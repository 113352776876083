import React, { useEffect, useState } from 'react';
import { Table, Flex, Button, Popconfirm } from 'antd';
import { deleteTransporter } from '../../../../redux/actions';

export const TransporterTable = ({ data, loading, dispatch, openModal, setSelectedTransporter }) => {
    const [transporters, setTransporters] = useState([]);

    useEffect(() => {
        if (data) {
            const transportersData = data.map((val) => {
                return ({
                    ...val,
                    address: { Address1: val.Address1, Address2: val.Address2, Address3: val.Address3, Address4: val.Address4 },
                    action: val
                });
            })
            setTransporters(transportersData);
        }
    }, [data]);

    const Actions = (value) => {
        const handleDelete = (e) => {
            console.log('<<delete-', value, e);
            dispatch(deleteTransporter(value.id));
        }
        const handleEdit = (e) => {
            console.log('<<Edit--', value);
            setSelectedTransporter(value);
            openModal();
        }
        return (
            <Flex gap={'small'}>
                <Button type='primary' onClick={handleEdit}>Edit</Button>
                <Popconfirm
                    title="Are you sure you want to delete this transporter?"
                    onConfirm={() => handleDelete(value.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button danger>Delete</Button>
                </Popconfirm>
            </Flex>
        );
    }
    const Address = (value) => {
        return (
            <div>
                <p className='mb-0'>{value.Address1}</p>
                <p className='mb-0'>{value.Address2}</p>
                <p className='mb-0'>{value.Address3}</p>
                <p className='mb-0'>{value.Address4}</p>
            </div>
        );
    }

    const columns = [
        {
            title: 'Transporter Name',
            dataIndex: 'Transporter_Name',
            key: 'Transporter_Name',
            width: 250
        },
        {
            title: 'Phone No',
            dataIndex: 'Phone_No',
            key: 'Phone_No',
            width: 170,
        },
        {
            title: 'Contact Persons',
            dataIndex: 'P1_Cont_Perc_Name',
            key: 'P1_Cont_Perc_Name',
            width: 200,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: 250,
            render: Address
        },
        {
            title: 'GSTIN/UIN No',
            dataIndex: 'GSTIN_No',
            key: 'GSTIN_No',
            width: 150,
        },
        {
            title: 'Transport Mode',
            dataIndex: 'Transport_Mode',
            key: 'Transport_Mode',
            width: 150,
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            width: 200,
            render: Actions
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={transporters}
            loading={loading}
            rowKey="id"
        />
    );
}
