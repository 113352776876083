import React, { useEffect, useState } from 'react';
import { Table, Flex, Button, Popconfirm } from 'antd';
import { deleteBroker } from '../../../../redux/actions';

export const BrokerTable = ({ data, loading, dispatch, openModal, setSelectedBroker }) => {
    const [brokers, setBrokers] = useState([]);

    useEffect(() => {
        if (data) {
            const brokersData = data.map((val) => {
                return ({
                    id: val.id,
                    name: val.Brocker_Name,
                    phone: { Phone_No1: val.Phone_No1, Phone_No2: val.Phone_No2 },
                    address: { Address1: val.Address1, Address2: val.Address2, Address3: val.Address3, Address4: val.Address4 },
                    contactPerson: { P1_Cont_Perc_Name: val.P1_Cont_Perc_Name, P2_Cont_Perc_Name: val.P2_Cont_Perc_Name },
                    email: val.Email,
                    brokaragePerc: val.Brokerage_Perc,
                    blackList: val.Black_List,
                    action: val
                });
            })
            setBrokers(brokersData);
        }
    }, [data]);

    const Actions = (value) => {
        const handleDelete = (e) => {
            dispatch(deleteBroker(value.id));
        }
        const handleEdit = (e) => {
            setSelectedBroker(value);
            openModal();
        }
        return (
            <Flex gap={'small'}>
                <Button type='primary' onClick={handleEdit}>Edit</Button>
                <Popconfirm
                    title="Are you sure you want to delete this broker?"
                    onConfirm={() => handleDelete(value.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button danger>Delete</Button>
                </Popconfirm>
            </Flex>
        );
    }
    const ContactPersons = (value) => {
        return (
            <div>
                <p className='mb-0'>{value.P1_Cont_Perc_Name}</p>
                <p className='mb-0'>{value.P2_Cont_Perc_Name}</p>
            </div>
        );
    }
    const Address = (value) => {
        return (
            <div>
                <p className='mb-0'>{value.Address1}</p>
                <p className='mb-0'>{value.Address2}</p>
                <p className='mb-0'>{value.Address3}</p>
                <p className='mb-0'>{value.Address4}</p>
            </div>
        );
    }
    const Phone = (value) => {
        return (
            <div>
                <p className='mb-0'>{value.Phone_No1}</p>
                <p className='mb-0'>{value.Phone_No2}</p>
            </div>
        );
    }

    const columns = [
        {
            title: 'Broker Name',
            dataIndex: 'name',
            key: 'name',
            width: 250
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 150
        },
        {
            title: 'Phone No',
            dataIndex: 'phone',
            key: 'phone',
            width: 200,
            render: Phone
        },
        {
            title: 'Brokerage Percentage',
            dataIndex: 'brokaragePerc',
            key: 'brokaragePerc',
            width: 50,
            render: text => (`${text}%`)
        },
        {
            title: 'Contact Persons',
            dataIndex: 'contactPerson',
            key: 'contactPerson',
            width: 200,
            render: ContactPersons
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: 250,
            render: Address
        },
        {
            title: 'Black List',
            dataIndex: 'blackList',
            key: 'blackList',
            width: 50,
            render: text => (text ? 'Yes' : 'No')
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            width: 250,
            render: Actions
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={brokers}
            loading={loading}
            rowKey="id"
        />
    );
}
