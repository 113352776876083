import { TRANSPORTER_REQUEST, CREATE_TRANSPORTER, GET_TRANSPORTERS, UPDATE_TRANSPORTER, DELETE_TRANSPORTER, TRANSPORTER_REQUEST_FAILURE } from '../types';

const initialState = {
  transporters: [],
  loading: false,
  error: null,
};

const transporterReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRANSPORTER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case GET_TRANSPORTERS: {
      return {
        ...state,
        transporters: [...action.payload],
        loading: false,
        error: null
      }
    }
    case CREATE_TRANSPORTER:
      return {
        ...state,
        transporters: [...state.transporters, action.payload],
        loading: false,
        error: null
      };
    case UPDATE_TRANSPORTER:
      const transportersData = state.transporters.slice(0);
      const uIndex = transportersData.findIndex((data) => data.id === action.payload.id);
      transportersData.splice(uIndex, 1, { ...action.payload.data });
      return {
        ...state,
        transporters: [ ...transportersData ],
        loading: false,
        error: null
      };
    case DELETE_TRANSPORTER:
      const transporters = state.transporters?.slice(0);
      const index = transporters.findIndex((data) => data.id === action.id);
      if (index > -1) {
        transporters.splice(index, 1);
      }
      return {
        ...state,
        transporters: [...transporters],
        loading: false,
        error: null
      }
    case TRANSPORTER_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }
    default:
      return state;
  }
};

export default transporterReducer;
