import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button } from 'antd';
import { loginUser } from '../../../redux/actions';
import '../styles/login.css';

export const Login = () => {
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const auth = useSelector((state) => state.auth);
    const [form] = Form.useForm();

    useEffect(() => {
        setError(auth.error || '');
        if (auth.error) {
            form.resetFields();
        }
    }, [auth.error, form]);

    const onFinish = (values) => {
        console.log('Values:', values);
        dispatch(loginUser(values));
    };

    const onChange = (e) => {
        if (error) {
            setError('');
        }
    };

    const onCancel = () => {
        form.resetFields();
    };

    return (
        <Form
            form={form}
            name='login'
            className='form'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            onChange={onChange}
            autoComplete='off'
        >
            <Form.Item
                label='Username'
                name='username'
                rules={[{ required: true, message: 'Please input your username!' }]}
            >
            <Input />
            </Form.Item>

            <Form.Item
                label='Password'
                name='password'
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
            <Input.Password />
            </Form.Item>

            <div className='btn-wrap'>
                <Button onClick={onCancel}>
                    Cancel
                </Button>
                <Button type='primary' htmlType='submit'>
                    Submit
                </Button>
            </div>
            {error ? <div className='error'>{error}</div> : null}
        </Form>
    );
}