import React, { useState, useEffect } from 'react' ;
import { Form, Input, InputNumber, Checkbox, Flex, Card, Button, Modal } from 'antd';
import { createBroker, updateBroker } from '../../../../redux/actions';
import '../styles/broker.css';

const { Item } = Form;

export const BrokerModal = ({ open, onClose, dispatch, broker }) => {
    const [isBlackList, setIsBlackList] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (broker?.id) {
            form.setFieldsValue(broker);
        } else if(open) {
            form.resetFields();
        }
    }, [broker, form, open]);

    const onCancel = () => {
        form.resetFields();
        onClose();
    }

    const onFinishForm = (values) => {
        if (broker?.id) {
            dispatch(updateBroker(broker.id, values));
        } else {
            dispatch(createBroker(values));
        }
        onCancel();
    }

    const onFinishFailedForm = (error) => {
        console.log('Finish failed-', error);
    }

    const onChangeBlackList = (event) => {
        setIsBlackList(event.target.checked);
        if (!event.target.checked) {
            form.resetFields(['Reason_For_Black_List']);
        }
    }
    
    return (
        <Modal
            title={broker?.id ? 'Edit Broker Details' : 'Add Broker Details'}
            open={open}
            onCancel={onCancel}
            width={700}
            footer={[
                <Button key={'secondary'} onClick={onCancel}>Cancel</Button>,
                <Button key={'primary'} type='primary' onClick={form.submit}>{broker?.id ? 'Update' : 'Save'}</Button>
            ]}
        >
            <Card className='b-card'>
                <Form
                    name={'broker-form'}
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={onFinishForm}
                    onFinishFailed={onFinishFailedForm}
                >
                    <Item name={'Brocker_Name'} label={'Name'} rules={[{ required: true, message: 'Broker name is required!' }]}>
                        <Input/>
                    </Item>
                    <Item name={'Address1'} label={'Address1'}>
                        <Input/>
                    </Item>
                    <Item name={'Address2'} label={'Address2'}>
                        <Input/>
                    </Item>
                    <Item name={'Address3'} label={'Address3'}>
                        <Input/>
                    </Item>
                    <Item name={'Address4'} label={'Address4'}>
                        <Input/>
                    </Item>
                    <Item name={'Phone_No1'} label={'Phone No 1'}>
                        <Input/>
                    </Item>
                    <Item name={'P1_Cont_Perc_Name'} label={'Contact Person Name'}>
                        <Input/>
                    </Item>
                    <Item name={'Phone_No2'} label={'Phone No 2'}>
                        <Input/>
                    </Item>
                    <Item name={'P2_Cont_Perc_Name'} label={'Contact Person Name'}>
                        <Input/>
                    </Item>
                    <Item name={'Email'} label={'Email'} rules={[{ type: 'email' }]}>
                        <Input type='email'/>
                    </Item>
                    <Item
                        name={'Brokerage_Perc'}
                        label={'Brokerage Perc(%)'}
                        wrapperCol={{ span: 4 }}
                    >
                        <InputNumber className='w-full'/>
                    </Item>
                    <Item label={'Black List?'}>
                        <Flex gap={'small'}>
                            <Item name={'Black_List'} initialValue={isBlackList} valuePropName={'checked'} noStyle>
                                <Checkbox onChange={onChangeBlackList}/>
                            </Item>
                            <Item name={'Reason_For_Black_List'} noStyle>
                                <Input disabled={!isBlackList}/>
                            </Item>
                        </Flex>
                    </Item>
                </Form>
            </Card>
        </Modal>
    );
}