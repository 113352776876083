import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setToken, setFinyear, getFinYears, getCompanies, setCompany } from '../../redux/actions';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const {
    auth: { user, token, finyear, error } = {},
    finyear: { finyears, loading: finyearLoading } = {},
    company: { companies, loading: companyLoading } = {}
} = useSelector((state) => state);
  const dispatch = useDispatch();
  
useEffect(() => {
  dispatch(getFinYears());
  dispatch(getCompanies());
  const token = localStorage.getItem('token');
  if (token) {
    dispatch(setToken(token));
  } else {
    setLoading(false);
  }
}, [dispatch]);

useEffect(() => {
  if (token) {
    setIsAuthenticated(!!token && !!finyear?.id);
  }
}, [token, finyear]);

useEffect(() => {
  if (error) {
    setIsAuthenticated(false);
    setLoading(false);
  }
}, [error])

useEffect(() => {
  if (!finyearLoading) {
    if (user && finyears.length) {
      const finyearId = localStorage.getItem('finyear');
      const id = parseInt(finyearId);
      if (user.Fin_Year_Ids.includes(id)) {
          const finyear = finyears.find((data) => (data.id === id));
          dispatch(setFinyear(finyear));
      }
      setLoading(false || companyLoading || finyearLoading);
    }
  }
}, [user, finyears, finyearLoading, companyLoading, dispatch]);

useEffect(() => {
  if (!companyLoading) {
    if (companies.length && user) {
      const companyId = localStorage.getItem('company');
      const id = parseInt(companyId);
      if (user.Company_Ids?.includes(id)) {
          const company = companies.find((data) => (data.id === id));
          dispatch(setCompany(company));
      }
      setLoading(false || companyLoading || finyearLoading);
    }
  }
}, [companies, user, companyLoading, finyearLoading, dispatch]);

//Logic: when companies and finyears are empty so, stop loader(But not working as expected)
// useEffect(() => {
//   if (!companyLoading && !finyearLoading) {
//     if (!companies.length && !finyears.length) {
//         setLoading(false);
//     }
//   }
// }, [companyLoading, finyearLoading, companies, finyears]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook to use auth context
export const useAuth = () => useContext(AuthContext);
