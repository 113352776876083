import { GET_STATES } from '../types';

const initialState = {
  states: []
};

const stateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATES:
      return {
        ...state,
        states: action.payload,
      };
    default:
      return state;
  }
};

export default stateReducer;
