import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, SET_FIN_YEAR, SET_TOKEN_USER, SET_COMPANY } from '../types';

const initialState = {
  loading: false,
  token: null,
  finyear: null,
  user: null,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        finyear: null,
        user: null,
        loading: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        loading: false,
        token: action.payload.token,
        user: action.payload.user,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        token: null,
        finyear: null,
        user: null,
        error: action.error,
      };
    case SET_TOKEN_USER:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
      }
    case SET_FIN_YEAR:
      localStorage.setItem('finyear', action.payload.id);
      return {
        ...state,
        finyear: action.payload
      }
    case SET_COMPANY:
      localStorage.setItem('company', action.payload.id);
      return {
        ...state,
        company: action.payload
      }
    default:
      return state;
  }
};

export default authReducer;
