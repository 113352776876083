import React, { useEffect, useState } from 'react';
import { Table, Flex, Button, Popconfirm } from 'antd';
import { deleteSchedule } from '../../../../redux/actions';

export const SchedulesTable = ({ data, loading, dispatch, openModal, setSelectedSchedule }) => {
    const [schedules, setSchedules] = useState([]);

    useEffect(() => {
        if (data) {
            const schedulesData = data.map((val) => {
                return ({
                    ...val,
                    action: val
                });
            })
            setSchedules(schedulesData);
        }
    }, [data]);

    const Actions = (value) => {
        const handleDelete = (e) => {
            dispatch(deleteSchedule(value.id));
        }
        const handleEdit = (e) => {
            setSelectedSchedule(value);
            openModal();
        }
        return (
            <Flex gap={'small'}>
                <Button type='primary' onClick={handleEdit}>Edit</Button>
                <Popconfirm
                    title="Are you sure you want to delete this schedule?"
                    onConfirm={() => handleDelete(value.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button danger>Delete</Button>
                </Popconfirm>
            </Flex>
        );
    }
    
    const columns = [
        {
            title: 'Schedule Name',
            dataIndex: 'Group_Name',
            key: 'Group_Name',
            width: 200
        },
        {
            title: 'Under Group',
            dataIndex: 'Under_Group',
            key: 'Under_Group',
            width: 100
        },
        {
            title: 'Sub Group Req.',
            dataIndex: 'Sub_Group_Req',
            key: 'Sub_Group_Req',
            width: 100,
            render: (req) => (req === 1 ? 'Yes' : (req === 0 ? 'No' : ''))
        },
        {
            title: 'EffectOn',
            dataIndex: 'EffectOn',
            key: 'EffectOn',
            width: 100,
        },
        {
            title: 'Sech. Index',
            dataIndex: 'Sech_Index',
            key: 'Sech_Index',
            width: 100,
        },
        {
            title: 'Effect on GP',
            dataIndex: 'Effect_On_GP',
            key: 'Effect_On_GP',
            width: 100,
            render: (val) => val ? 'True' : 'False'
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'Description',
            width: 200,
        },
        {
            title: 'Schedule Type',
            dataIndex: 'Schedule_Type',
            key: 'Schedule_Type',
            width: 100,
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            width: 150,
            render: Actions
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={schedules}
            loading={loading}
            rowKey="id"
        />
    );
}
