import axios from '../../config/axiosConfig';
import { GET_FIN_YEARS, FIN_YEAR_REQUEST, FIN_YEAR_FAILURE } from '../types';

export const getFinYears = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: FIN_YEAR_REQUEST });
            const response = await axios.get('/finyear/getAll');
            if (response.data) {
                dispatch({ type: GET_FIN_YEARS, payload: response.data });
            } else {
                dispatch({ type: FIN_YEAR_FAILURE, error: 'Get finyears data failed.' });
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: FIN_YEAR_FAILURE, error: error.message });
        }
    };
};