import axios from '../../config/axiosConfig';
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, SET_FIN_YEAR, SET_TOKEN_USER, SET_COMPANY } from '../types';

export const loginUser = (credentials) => {
    return async (dispatch) => {
        dispatch({ type: LOGIN_REQUEST });
        try {
            const response = await axios.post('/auth/login', credentials);
            if (response.data?.token) {
                dispatch({ type: LOGIN_SUCCESS, payload: response.data });
            } else {
                localStorage.removeItem('token');
                localStorage.removeItem('finyear');
                dispatch({ type: LOGIN_FAILURE, error: response.data?.message });      
            }
        } catch (error) {
            localStorage.removeItem('token');
            localStorage.removeItem('finyear');
            dispatch({ type: LOGIN_FAILURE, error: error.message });
        }
    };
};

export const setToken = (token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LOGIN_REQUEST });
            const response = await axios.get('/auth/user', { params: { token } });
            if (response.data?.user) {
                dispatch({ type: SET_TOKEN_USER, payload: { token, user: response.data?.user } });
            } else {
                dispatch({ type: LOGIN_FAILURE, error: response.data?.error });
            }
        } catch (error) {
            dispatch({ type: LOGIN_FAILURE, error: error.message });
        }
    }
}

export const setFinyear = (finyear) => {
    return async (dispatch) => {
        dispatch({ type: SET_FIN_YEAR, payload: finyear });
    }
}

export const setCompany = (company) => {
    return async (dispatch) => {
        dispatch({ type: SET_COMPANY, payload: company });
    }
}
