import { COMPANY_REQUEST, CREATE_COMPANY, GET_COMPANIES, COMPANY_REQUEST_FAILURE } from '../types';

const initialState = {
  companies: [],
  loading: false,
  error: null,
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case GET_COMPANIES: {
      return {
        ...state,
        companies: [...action.payload],
        loading: false,
        error: null
      }
    }
    case CREATE_COMPANY:
      return {
        ...state,
        companies: [...state.companies, action.payload],
        loading: false,
        error: null
      };
    case COMPANY_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }
    default:
      return state;
  }
};

export default companyReducer;
