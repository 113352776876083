import { GET_FIN_YEARS, FIN_YEAR_REQUEST, FIN_YEAR_FAILURE } from '../types';

const initialState = {
  finyears: [],
  loading: false,
  error: null
};

const finyearReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIN_YEAR_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case GET_FIN_YEARS:
      return {
        ...state,
        finyears: action.payload,
        loading: false,
        error: null
      };
    case FIN_YEAR_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }
    default:
      return state;
  }
};

export default finyearReducer;
