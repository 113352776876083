import React, { useEffect, useState } from 'react';
import { Button, Typography, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { BrokerModal } from './BrokerModal';
import { BrokerTable } from './BrokersTable';
import { getBrokers } from '../../../../redux/actions';

const { Title } = Typography;

export const BrokerView = () => {
    const [open, setOpen] = useState(false);
    const [selectedBroker, setSelectedBroker] = useState(null);
    const state = useSelector((state) => state.broker);
    const [api, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getBrokers());
    }, [dispatch]);

    useEffect(() => {
        const openErrorNotification = () => {
          api.error({
            message: 'Broker Failed',
            description: state.error,
            placement: 'topRight',
          });
        };
        if (state.error) {
          openErrorNotification();
        }
      }, [state.error, api]);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => {
        setSelectedBroker(null);
        setOpen(false);
    }

    return (
        <div className='container'>
            {contextHolder}
            <BrokerModal
                open={open}
                onClose={onCloseModal}
                dispatch={dispatch}
                broker={selectedBroker}
            />
            <Title>Broker</Title>
            <div className='add-broker'>
                <Button type='primary' onClick={onOpenModal}><PlusOutlined />Add Broker</Button>
            </div>
            <BrokerTable
                data={state.brokers || []}
                loading={state.loading}
                dispatch={dispatch}
                openModal={onOpenModal}
                setSelectedBroker={setSelectedBroker}
            />
        </div>
    );
}

export default BrokerView;