import axios from '../../config/axiosConfig';
import { CREATE_SCHEDULE, GET_SCHEDULES, SCHEDULE_REQUEST, DELETE_SCHEDULE, UPDATE_SCHEDULE, SCHEDULE_REQUEST_FAILURE } from '../types';

export const getSchedules = () => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: SCHEDULE_REQUEST });
            const { auth } = getState();
            const response = await axios.get('/schedule/getAll', { params: { userId: auth.user?.id, companyId: auth.company?.id }});
            if (response.data) {
                dispatch({ type: GET_SCHEDULES, payload: response.data });
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: SCHEDULE_REQUEST_FAILURE, error: error.message });
        }
    };
};

export const createSchedule = (schedule) => {
    return async (dispatch, getState) => {
        try {
            const { auth } = getState();
            dispatch({ type: SCHEDULE_REQUEST });
            const newSchedule = {
                ...schedule,
                Company_Id: auth.company?.id,
                Created_User: auth.user?.id,
                Modified_User: auth.user?.id
            };

            const response = await axios.post('/schedule/add', newSchedule);
            if (response.data) {
                dispatch({ type: CREATE_SCHEDULE, payload: response.data });
            } else {
                dispatch({ type: SCHEDULE_REQUEST_FAILURE, error: 'Schedule create failed.' });
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: SCHEDULE_REQUEST_FAILURE, error: error.message });
        }
    };
};

export const updateSchedule = (id, data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SCHEDULE_REQUEST });
            const response = await axios.put('/schedule/update', { id, data });
            if (response.data) {
                dispatch({ type: UPDATE_SCHEDULE, payload: { id, data: response.data } });
            } else {
                dispatch({ type: SCHEDULE_REQUEST_FAILURE, error: 'Schedule update failed.' });
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: SCHEDULE_REQUEST_FAILURE, error: error.message });
        }
    };
};

export const deleteSchedule = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SCHEDULE_REQUEST });
            const response = await axios.delete(`/schedule/delete/${id}`);
            if (response.data) {
                dispatch({ type: DELETE_SCHEDULE, id: id });
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: SCHEDULE_REQUEST_FAILURE, error: error.message });
        }
    };
};