import React, { useState, useEffect } from 'react' ;
import { Form, Input, InputNumber, Checkbox, Flex, Card, Button, Modal, Select } from 'antd';
import { createSchedule, updateSchedule } from '../../../../redux/actions';

const { Item } = Form;
const { Option } = Select;

export const ScheduleModal = ({ open, onClose, dispatch, schedule }) => {
    const [form] = Form.useForm();
    const [hideOpening, setHideOpening] = useState(true);

    useEffect(() => {
        if (schedule?.id) {
            form.setFieldsValue(schedule);
        } else if (open) {
            form.resetFields();
        }
    }, [schedule, form, open]);

    const onCancel = () => {
        form.resetFields();
        onClose();
    }

    const onFinishForm = (values) => {
        const newSchedule = {
            ...values,
            Under_Group: values.Under_Group[0],
            Sub_Group_Req: parseInt(values.Sub_Group_Req),
            Sech_Index: parseInt(values.Sech_Index)
        }
        if (schedule?.id) {
            dispatch(updateSchedule(schedule.id, newSchedule));
        } else {
            dispatch(createSchedule(newSchedule));
        }
        onCancel();
    }

    const onFinishFailedForm = (error) => {
        console.log('Finish failed-', error);
    }

    const onChangeEffectOn = (value) => {
        if (value === 'Trading Account') {
            setHideOpening(false);
        } else {
            setHideOpening(true);
            form.resetFields(['As_Opening']);
        }
    }
    
    return (
        <Modal
            title={schedule?.id ? 'Edit Schedule Details' : 'Add Schedule Details'}
            open={open}
            onCancel={onCancel}
            width={700}
            footer={[
                <Button key={'secondary'} onClick={onCancel}>Cancel</Button>,
                <Button key={'primary'} type='primary' onClick={form.submit}>{schedule?.id ? 'Update' : 'Save'}</Button>
            ]}
        >
            <Card className='b-card'>
                <Form
                    name={'schedule-form'}
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={onFinishForm}
                    onFinishFailed={onFinishFailedForm}
                >
                    <Item name={'Group_Name'} label={'Schedule Name'} rules={[{ required: true, message: 'Schedule name is required!' }]}>
                        <Input/>
                    </Item>
                    <Item name={'Under_Group'} label={'Under Group'}>
                        <Select
                            mode="tags"
                            allowClear={true}
                            style={{ width: '100%' }}
                            maxCount={1}
                        />
                    </Item>
                    <Item name={'Sub_Group_Req'} label={'Sub Group Req.'}>
                        <Select>
                            <Option value={1}>Yes</Option>
                            <Option value={0}>No</Option>
                        </Select>
                    </Item>
                    <Item label={'Effect ON'}>
                        <Flex gap={'small'}>
                            <Item name={'EffectOn'} className={'effect-on'}>
                                <Select onChange={onChangeEffectOn}>
                                    <Option value={'Balance Sheet'}>Balance Sheet</Option>
                                    <Option value={'Profit And Loss'}>Profit & Loss</Option>
                                    <Option value={'Trading Account'}>Trading A/C</Option>
                                </Select>
                            </Item>
                            <Item name={'Sech_Index'} label={'Sech. Index'} className={'s-right'}>
                                <InputNumber/>
                            </Item>
                        </Flex>
                    </Item>
                    <Item wrapperCol={{ span: 18, offset: 6 }}>
                        <Flex gap={'small'}>
                            {!hideOpening ? (
                                <Item>
                                    <Item name={'As_Opening'} valuePropName='checked' noStyle><Checkbox/></Item> As Opening?
                                </Item>
                            ) : null}
                            <Item className={'s-right'} >
                                <Item name={'Effect_On_GP'} valuePropName='checked' noStyle><Checkbox/></Item> Effect on GP
                            </Item>
                        </Flex>
                    </Item>
                    <Item name={'Description'} label={'Description'}>
                        <Input/>
                    </Item>
                    <Item name={'Schedule_Type'} label={'Schedule Type'}>
                        <Select>
                            <Option value={'Expense'}>Expense</Option>
                            <Option value={'Income'}>Income</Option>
                        </Select>
                    </Item>
                </Form>
            </Card>
        </Modal>
    );
}