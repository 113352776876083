import React, { useEffect } from 'react' ;
import { Form, Input, Card, Button, Modal, Select } from 'antd';
import { createTransporter, updateTransporter } from '../../../../redux/actions';

const { Item } = Form;
const { Option } = Select;

export const TransporterModal = ({ open, onClose, dispatch, broker }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (broker?.id) {
            form.setFieldsValue(broker);
        } else if(open) {
            form.resetFields();
        }
    }, [broker, form, open]);

    const onCancel = () => {
        form.resetFields();
        onClose();
    }

    const onFinishForm = (values) => {
        if (broker?.id) {
            dispatch(updateTransporter(broker.id, values));
        } else {
            dispatch(createTransporter(values));
        }
        onCancel();
    }

    const onFinishFailedForm = (error) => {
        console.log('Finish failed-', error);
    }
    
    return (
        <Modal
            title={broker?.id ? 'Edit Transporter Details' : 'Add Transporter Details'}
            open={open}
            onCancel={onCancel}
            width={700}
            footer={[
                <Button key={'secondary'} onClick={onCancel}>Cancel</Button>,
                <Button key={'primary'} type='primary' onClick={form.submit}>{broker?.id ? 'Update' : 'Save'}</Button>
            ]}
        >
            <Card className='b-card'>
                <Form
                    name={'transporter-form'}
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={onFinishForm}
                    onFinishFailed={onFinishFailedForm}
                >
                    <Item name={'Transporter_Name'} label={'Name'} rules={[{ required: true, message: 'Transporter name is required!' }]}>
                        <Input/>
                    </Item>
                    <Item name={'Address1'} label={'Address1'}>
                        <Input/>
                    </Item>
                    <Item name={'Address2'} label={'Address2'}>
                        <Input/>
                    </Item>
                    <Item name={'Address3'} label={'Address3'}>
                        <Input/>
                    </Item>
                    <Item name={'Address4'} label={'Address4'}>
                        <Input/>
                    </Item>
                    <Item name={'Phone_No'} label={'Phone No'}>
                        <Input/>
                    </Item>
                    <Item name={'P1_Cont_Perc_Name'} label={'Contact Person Name'}>
                        <Input/>
                    </Item>
                    <Item name={'GSTIN_No'} label={'GSTIN/UIN NO'}>
                        <Input/>
                    </Item>
                    <Item name={'Transport_Mode'} label={'Transport Mode'}>
                        <Select>
                            <Option value={'Road'}>Road</Option>
                            <Option value={'Rail'}>Rail</Option>
                            <Option value={'Air'}>Air</Option>
                            <Option value={'Ship'}>Ship</Option>
                        </Select>
                    </Item>
                </Form>
            </Card>
        </Modal>
    );
}