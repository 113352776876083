import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  DesktopOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import { AppRoutes } from '../../Routes/Routes';
import { setToken } from '../../../redux/actions';
import '../styles/layout.css';

const { Header, Content, Sider } = Layout;

const items1 = ['1', '2', '3'].map((key) => ({
  key,
  label: `nav ${key}`,
}));

const menu = [
  { key: 'dashboard', label: 'Dashboard', icon: React.createElement(PieChartOutlined) },
  {
    key: 'masters',
    label: 'Masters',
    icon: React.createElement(DesktopOutlined),
    children: [
      { key: 'company', label: 'Company' },
      { key: 'broker', label: 'Broker' },
      { key: 'schedule', label: 'Schedule' },
      { key: 'transporter', label: 'Transporter' },
      { key: 'state', label: 'State' }
    ]
  }
];

const LayoutComponent = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect (() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(setToken(token));
    }
  }, [dispatch]);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const handleMenuClick = ({ keyPath }) => {
    const path = keyPath.reverse().join('/');
    navigate(`/${path}`);
  };

  return pathname === '/login' ? (
    <Layout className='layout'>
      <AppRoutes/>
    </Layout>
  ) : (
    <Layout className='layout'>
      <Header className='header'>
        <div className='demo-logo' />
        <Menu
          theme='dark'
          mode='horizontal'
          className='header-menu'
          defaultSelectedKeys={['2']}
          items={items1}
        />
      </Header>
      <Layout>
        <Sider
          style={{ background: colorBgContainer }}
          collapsible={true}
          collapsed={collapsed}
          onCollapse={onCollapse}
        >
          <Menu
            mode='inline'
            className='side-menu'
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            items={menu}
            onClick={handleMenuClick}
          />
        </Sider>
        <Layout className='p-10'>
          <Content
            style={{
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <AppRoutes/>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;